<template>
    <div class="d-flex flex-column" v-if="field.data_type_enum === 15 || field.slug === 'phone'">
        <label for="" class="form-label">{{ field.placeholder }}</label>
        <span v-if="includeHelp" class="label-color ">{{ field.help }}</span>
        <Phone v-model="localValue" :field="field" :validations="validations"/>
    </div>
    <div class="d-flex flex-column" v-else-if="field.data_type_enum === 10 || field.data_type_enum === 11">
        <label for="" class="form-label">{{ field.placeholder }}</label>
        <span v-if="includeHelp" class="label-color ">{{ field.help }}</span>
        <InputField v-model="localValue" :field="field" :validations="validations"/>
    </div>
    <div class="mb-4 d-flex flex-nowrap w-100" v-else-if="field.data_type_enum === 21" style="gap:5px;">
        <Checkbox :id="field.slug" class="form-label " v-model="localValue" :field="field" :validations="validations"/>
        <label :for="field.slug" class="txt-white text-nowrap txt-check" v-html="field.help"></label>
    </div>
    <div class="d-flex flex-column" v-else-if="field.data_type_enum === 20">
        <label for="" class="form-label">{{ field.placeholder }}</label>
        <span v-if="includeHelp" class="label-color ">{{ field.help }}</span>
        <Number v-model="localValue" :field="field" :validations="validations"/>
    </div>
</template>
<script>
import InputField from './fields/Input.vue';
import Checkbox from './fields/Checkbox.vue';
import Number from './fields/Number.vue';
import Phone from './fields/Phone.vue';

export default {
    inject: ['$validator'],
    props: {
        field: {
            type: Object,
            required: true
        },
        value: {
            required: true
        },
        includeHelp: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        localValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        validations: function () {
            this.field.min_length = this.field.slug === 'zip_code' ? '5' : this.field.min_length
            let minLength = this.field.min_length ? `min:${this.field.min_length}` : null;
            let maxLength = this.field.max_length && this.field.slug !== 'phone' ? `max:${this.field.max_length}` : null;

            let lengthValidations = minLength && maxLength ? `${minLength}|${maxLength}` : (minLength || maxLength || '')
            let customValidator = ['email', 'phone'].includes(this.field.slug);

            let validator = (customValidator ? `${this.field.required ? 'required|' : ''}${this.field.slug}` : this.field.required ? 'required' : '') + (lengthValidations ? `|${lengthValidations}` : '')
            validator = this.field.slug === 'zip_code' ? validator + '|numeric' : validator
            return validator
        }
    },
    components: {
        InputField, Checkbox, Number, Phone
    }
}
</script>

<style lang="scss">
.register-form {
    .vue-phone-number-input {
        border-color: rgb(216, 216, 216) !important;
        background-color: transparent !important;
        border: 1px solid;
    }

    .country-selector {
        .country-selector__country-flag {
            top: 14px !important;
            left: 40px !important;
        }

        .country-selector__label {
            font-size: 10px !important;
        }

        .country-selector__toggle {
            top: calc(40% - 11px) !important;
        }
    }

    .input-tel {
        left: 1px;

        .input-tel__label {
            font-size: 9px;
            top: 0;
        }
    }
}

.label-color {
    color: gray;
    font-size: 15px;
    margin-bottom: 3px;
}
</style>