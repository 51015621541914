
<div class="d-flex flex-column" v-if="field.data_type_enum === 15 || field.slug === 'phone'">
    <label for="" class="form-label">{{ field.placeholder }}</label>
    <span v-if="includeHelp" class="label-color ">{{ field.help }}</span>
    <Phone v-model="localValue" :field="field" :validations="validations"/>
</div>
<div class="d-flex flex-column" v-else-if="field.data_type_enum === 10 || field.data_type_enum === 11">
    <label for="" class="form-label">{{ field.placeholder }}</label>
    <span v-if="includeHelp" class="label-color ">{{ field.help }}</span>
    <InputField v-model="localValue" :field="field" :validations="validations"/>
</div>
<div class="mb-4 d-flex flex-nowrap w-100" v-else-if="field.data_type_enum === 21" style="gap:5px;">
    <Checkbox :id="field.slug" class="form-label " v-model="localValue" :field="field" :validations="validations"/>
    <label :for="field.slug" class="txt-white text-nowrap txt-check" v-html="field.help"></label>
</div>
<div class="d-flex flex-column" v-else-if="field.data_type_enum === 20">
    <label for="" class="form-label">{{ field.placeholder }}</label>
    <span v-if="includeHelp" class="label-color ">{{ field.help }}</span>
    <Number v-model="localValue" :field="field" :validations="validations"/>
</div>
