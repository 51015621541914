<template>
    <input type="checkbox" v-model="localValue" :placeholder="field.placeholder" :name="field.slug" :data-vv-as="field.title" v-validate="validations">
</template>
<script>
export default {
    inject: ['$validator'],
    props: {
        field: {
            type: Object,
            required: true
        },
        value: {
            required: true
        },
        validations: {
            type: String,
            default: 'required'
        }
    },
    computed: {
        localValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
}
</script>